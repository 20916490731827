import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 90%;
    padding: 0px 120px;
`;

// export const StyledContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
// `;

export const StyledDescription = styled.p(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size:  ${p.theme.fontSizes.innerheading};
    font-style: normal;
    font-weight: 100;
    line-height: 38px;
    letter-spacing: -0.02em;
`
);

export const StyledContainerWrapper = styled.div`
    width: 100%;
    display: flex;
    /* margin-top: 50px; */
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 768px) {
        padding: 0% 5%;
        margin-top: 0px;
        flex-direction: column;
    }
`;
export const StyledLeftContainer = styled.div`
    width: 45%;
    margin-right: 15px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const StyledRightContainer = styled.div`
    width: 40%;
    margin-top: 60px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const StyledImage = styled.img`
    width: 100%;
`;

export const ButtonContainer = styled.div`
    flex-direction: column;
    display: flex;
`;
