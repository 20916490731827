import React from 'react';
import { StyledBox } from './styles';
import { INameIcon } from './types';

const NameIcon: React.FunctionComponent<INameIcon> = ({
    name,
    color
}) => {
    const [letters, setLetters] = React.useState('');
    React.useEffect(() => {
        const value = name;
        const [first, ...rest] = value.trim().split(' ');
        const last = rest[rest.length - 1];
        if (first && last) {
            setLetters(`${first[0]}${last[0]}`.trim().toUpperCase());
        } else {
            setLetters(name[0].toUpperCase());
        }
    }, [name]);
    return <StyledBox $color={color}>{letters}</StyledBox>;
};

export default NameIcon;
