import React from 'react';
import { ToastContext } from './ToastProvider';
import ToastProvider from './ToastProvider';
import { IToastType } from './Toast/types';
export interface IToastContext {
    displayToast: (type: IToastType, title: string, body: string, expires?: number) => string;
    removeToast: (id: string) => string;
}
export const useToast = (): IToastContext | null => {
    return React.useContext(ToastContext);
};
export { ToastProvider };
