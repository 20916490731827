import React from 'react';
import { IFeedbackStar } from './types';
import { FaStar } from 'react-icons/fa';
const FeedbackStar: React.FunctionComponent<IFeedbackStar> = ({ filled, onClick }) => {
    return (
        <FaStar
            color={filled ? '#FDD506' : '#ffffff'}
            size={40}
            onClick={onClick}
            style={{ stroke: filled ? '#FDD506' : '#595959', strokeWidth: '15' }}
        />
    );
};
export default FeedbackStar;
