import styled from 'styled-components';

export const StyleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyleStarContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
`;

export const StyleStarValueContainer = styled.div`
    margin-top: 20px;
    color: ${(p) => p.theme.colors.loopEmerald};
    font-style: normal;
    font-weight: 600;
    font-size: ${(p) => p.theme.fontSizes.heading};
`;
