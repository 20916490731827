import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    width: 100%
    min-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 520px;
    overflow: scroll;
    margin-bottom:60px;
    ::-webkit-scrollbar{
        display: none;
    }
    /* overflow-y: hidden; */
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    color: ${(p) => p.theme.colors.loopEmerald};
    margin: 5px 0px 20px 0px;
    margin-top: -5px;
    font-size: ${(p) => p.theme.fontSizes.landing};
    font-weight: 100;
    width: 90%;
    margin-right: auto;
    font-weight: 400;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 0;
    background: #e6efed;
    padding: 25px 43px;
    justify-content: space-between;
    width: 90%;
    /* padding: 50px; */
`;

export const StyledFeedbackWrapper = styled.div`
    display: flex;
    width: 100%;
    /* background: red; */
    margin-bottom: 10px;
    align-items: center;
`;

export const StyledDivIcon = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: ${(p) => p.theme.colors.purple};
    display: flex;
`;

export const StyledPDTable = styled.div`
    background: ${(p) => p.theme.colors.lightGrey};
    border-radius: 16px;
    margin: 15px 0px 50px 0px;
    padding: 10px 25px;
`;

export const SelectUL = styled.ul`
    /* background-color: black; */
    border-radius: 10px;
    /* z-index: 100; */
    display: flex;
`;

export const SelectULColumn = styled.ul`
    border-radius: 10px;
    z-index: 100;
    display: flex;
    flex-direction: column;
`;

export const SelectLI = styled.li`
    padding: 10px 40px 10px 10px;
    grid-column: 1/3;
    color: ${(p) => p.theme.colors.beyondGrey};
`;

export const StyledButton = styled.div(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    border: 2px solid ${p.theme.colors.loopEmerald};
    font-size: ${p.theme.fontSizes.mediumFont};
    background-color: ${p.theme.colors.white};
    font-weight: 700;
    cursor: pointer;
    border-radius: 16px;
    padding: 15px 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:'Work Sans';
    
`
);
