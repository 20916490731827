import styled from 'styled-components';
import theme from '../../../theme';

export const YellowText = styled.span`
    font-size: 20px;
    color: ${theme.colors.coverYellow};
`;
export const TitleText = styled.span`
    font-size: 16px;
    color: ${theme.colors.white};
`;

export const StyledSection = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
`;
