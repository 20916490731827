import React from 'react';
import { Button } from '../../pages/LoginContainer/components/atoms';
import { useHistory } from 'react-router';
import {
    StyledContainer,
    StyledDescription,
    StyledContainerWrapper,
    StyledLeftContainer,
    StyledRightContainer,
    StyledImage,
    ButtonContainer
} from './styles';
import { ITopUpUnAvailableInfo } from './types';
import theme from '../../../theme';
import { MAIN_ROUTES } from '../../../utils/constants/RouteConstants';

const TopUpUnAvailableInfo: React.FunctionComponent<ITopUpUnAvailableInfo> = ({ title, description, imageSrc }) => {
    const history = useHistory();
    const redirectToHome = () => {
        history.push(MAIN_ROUTES.topUpLanding);
    };
    return (
        <StyledContainer>
            <StyledContainerWrapper>
                <StyledLeftContainer>
                    {title}
                    <StyledDescription>{description}</StyledDescription>
                    <ButtonContainer>
                        <div>
                            <Button
                                type='secondary'
                                fullWidth={false}
                                color={theme.colors.coverUpGreen}
                                onClick={redirectToHome}
                                borderRadius='16px'
                                fontColor={theme.colors.loopEmerald}
                            >
                                Go back to Home Page
                            </Button>
                        </div>
                    </ButtonContainer>
                </StyledLeftContainer>
                <StyledRightContainer>
                    <StyledImage src={imageSrc} />
                </StyledRightContainer>
            </StyledContainerWrapper>
        </StyledContainer>
    );
};

export default TopUpUnAvailableInfo;
