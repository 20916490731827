import { IPolicyListSlice } from './types';

export const initialState: IPolicyListSlice = {
    policyList: {
        data: null,
        loading: false,
        error: null
    },
    policyDetails: {
        data: null,
        loading: false,
        error: null
    },
    policyBenefits: {
        data: null,
        loading: false,
        error: null
    },
    topupPolicyDetails: {
        data: null,
        loading: false,
        error: null
    },
    memberTopUp: {
        data: null,
        loading: false,
        error: null
    },
    paymentOrder: {
        data: null,
        loading: false,
        error: null
    },
    paymentOrderStatus: {
        data: null,
        loading: false,
        error: null
    },
    feedbackDetails: {
        data: null,
        loading: false,
        error: null
    },
    enrolUser: {
        data: null,
        loading: false,
        error: null
    }
};
