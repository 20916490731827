import { createSlice } from '@reduxjs/toolkit';
import { fetchFaqsData, getTopUpPolicyData, postTopUpUserData } from './thunk';
import { initialState } from './initialState';

const topUpLandingPageSlice = createSlice({
    name: 'TopUpLandingPageSlice',
    initialState,
    reducers: {
        saveTopUpPolicyData(state, action) {
            state.policySelectionData = action.payload.data;
        }
    },
    extraReducers: {
        [postTopUpUserData.pending as any]: (state: any): any => {
            state.crudTopUp.loading = true;
            state.crudTopUp.error = null;
        },
        [postTopUpUserData.fulfilled as any]: (state: any, action: any): any => {
            state.crudTopUp.loading = false;
            state.crudTopUp.data = action.payload.crudTopUp.data;
            state.crudTopUp.error = null;
        },
        [postTopUpUserData.rejected as any]: (state: any, { error }): any => {
            state.crudTopUp.loading = false;
            state.crudTopUp.error = error;
        },
        [getTopUpPolicyData.pending as any]: (state: any): any => {
            state.policyTopUpData.loading = true;
            state.policyTopUpData.error = null;
        },
        [getTopUpPolicyData.fulfilled as any]: (state: any, action: any): any => {
            state.policyTopUpData.loading = false;
            state.policyTopUpData.data = action.payload.data;
            state.policyTopUpData.error = null;
        },
        [getTopUpPolicyData.rejected as any]: (state: any, { error }): any => {
            state.policyTopUpData.loading = false;
            state.policyTopUpData.error = error;
        },
        [fetchFaqsData.pending as any]: (state) => {
            state.TopUpDataFaq.loading = true;
            state.TopUpDataFaq.error = null;
            state.TopUpDataFaq.data = null;
        },
        [fetchFaqsData.rejected as any]: (state, { error }) => {
            state.TopUpDataFaq.error = error;
            state.TopUpDataFaq.data = null;
            state.TopUpDataFaq.loading = false;
        },
        [fetchFaqsData.fulfilled as any]: (state, { payload }) => {
            state.TopUpDataFaq.loading = false;
            state.TopUpDataFaq.error = null;
            state.TopUpDataFaq = payload.TopUpDataFaq;
        }
    }
});
/* eslint-disable camelcase */
export { postTopUpUserData };
export const selectTopUpUserData = (state: { TopUp_LandingPage: { crudTopUp: any } }): any =>
    state.TopUp_LandingPage.crudTopUp;
export const selectTopUpPolicyData = (state: { TopUp_LandingPage: { policyTopUpData: any } }): any =>
    state.TopUp_LandingPage.policyTopUpData;
export default topUpLandingPageSlice.reducer;
export const { saveTopUpPolicyData } = topUpLandingPageSlice.actions;
