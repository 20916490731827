import { createAsyncThunk } from '@reduxjs/toolkit';
import { postTopUpLanding, getTopUpData, fetchFaqs } from '../../../adapters';
import { IAdapterData } from '../../types';

const TOP_UP_LANDING_ACTION_TYPES = {
    POST_USER_CONTACT: 'topUpLanding/postUserData',
    GET_TOP_UP_POLICY: 'topUpLanding/getPolicyData',
    FETCH_FAQ: 'topUpLanding/faqs'
};

export const postTopUpUserData = createAsyncThunk(
    TOP_UP_LANDING_ACTION_TYPES.POST_USER_CONTACT,
    async (reqBody: { mobile: string; email: string; event: string }) => {
        try {
            const response: any = await postTopUpLanding(reqBody);
            return {
                crudTopUp: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (error) {
            console.log('error post landing:', error);
            throw error;
        }
    }
);

export const getTopUpPolicyData = createAsyncThunk(
    TOP_UP_LANDING_ACTION_TYPES.GET_TOP_UP_POLICY,
    async (reqBody: { policyId: string }) => {
        try {
            const response: any = await getTopUpData(reqBody.policyId);
            return {
                policyTopUpData: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (error) {
            console.log('error post landing:', error);
            throw error;
        }
    }
);

export const fetchFaqsData = createAsyncThunk(TOP_UP_LANDING_ACTION_TYPES.FETCH_FAQ, async () => {
    try {
        const response: any = await fetchFaqs();
        return {
            TopUpDataFaq: {
                loading: false,
                data: response,
                error: null
            } as IAdapterData
        };
    } catch (error) {
        console.log('error post landing:', error);
        throw error;
    }
});
