import React from 'react';
import { CloseIcon, ToastSuccess } from '../../../../assets/img';
import {
    StyledBody,
    StyledContainer,
    StyledTitle,
    toastStyles,
    StyledClose,
    StyledContent,
    StyledIcon
} from './styles';
import { IToast } from './types';

const Toast: React.FunctionComponent<IToast> = ({ type, title, body, expires, forceExitAnimation = false }) => {
    const [isVisible, setIsVisible] = React.useState(true);
    const [endAnimationStart, setEndAnimationStart] = React.useState(false);
    const timeoutA = React.useRef<ReturnType<typeof setTimeout> | null>(null);
    const timeoutB = React.useRef<ReturnType<typeof setTimeout> | null>(null);
    React.useEffect(() => {
        if (expires) {
            // to remove the toast from the dom
            timeoutA.current = setTimeout(() => {
                setIsVisible(false);
            }, expires);
            // to start the fade out animation at the end
            timeoutB.current = setTimeout(() => {
                setEndAnimationStart(true);
            }, expires - 1000);
        }
    });
    React.useEffect(() => {
        if (forceExitAnimation) {
            setEndAnimationStart(true);
        }
    }, [forceExitAnimation]);
    const getStylesFromType = () => {
        switch (type) {
        case 'success':
            return toastStyles.success;
        case 'error':
            return toastStyles.error;
        case 'warning':
            return toastStyles.warning;
        default:
            return toastStyles.success;
        }
    };
    const handleClose = () => {
        timeoutA.current && clearTimeout(timeoutA.current);
        timeoutB.current && clearTimeout(timeoutB.current);
        setEndAnimationStart(true);
        setTimeout(() => {
            setIsVisible(false);
        }, 500);
    };
    return (
        (isVisible && (
            <StyledContainer $styles={getStylesFromType()} $endAnimationStart={endAnimationStart}>
                <StyledClose src={CloseIcon} onClick={handleClose} />
                <StyledIcon src={ToastSuccess} />
                <StyledContent>
                    <StyledTitle>{title}</StyledTitle>
                    <StyledBody>{body}</StyledBody>
                </StyledContent>
            </StyledContainer>
        )) || <></>
    );
};

export default Toast;
