import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: relative;
    flex-grow: 1;
    width: 100vw;
    height: 100vh;
    background-color: ${(p) => p.theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    justify-content: start;
    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 100px;
    }
`;
export const StyledHeading = styled.h2`
    color: ${(p) => p.theme.colors.black};
    margin-bottom: 0;
`;
export const StyledImage = styled.img`
    margin: 20px 0px;
`;

export const StyledSignature = styled.div`
    width: 100%;
    text-align: right;
`;

export const StyledContent = styled.div`
    max-width: 600px;
    font-size: 16px;
    line-height: 1.6;
    color: #595959;
`;

export const StyledPara = styled.p`
    text-align: center;
`;
