import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    display:flex;
    background-color: ${p.theme.colors.loopEmerald};
    padding:35px;
    padding-bottom:0px;
    justify-content: space-between;
    align-items: flex-start;
    height:213px;
    border-radius: 16px;
    margin: 20px 0px 30px 0px;
`
);

export const StyledSuccessWrapper = styled.div`
    display: flex;
`;

export const FlexWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    height: 100%;
`;
export const StyledSuccessTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledSuccessTextLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: ${(p) => p.theme.fontSizes.heading};
    color: ${(p) => p.theme.colors.white};
    text-align: left;
`;

export const StyledSuccessTextSpan = styled.span`
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${(p) => p.theme.colors.white};
`;

export const StyledSuccessTextBTNWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledBTNWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-left: 10px;
`;

export const StyledSuccessTextBTN = styled.button`
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: ${(p) => p.theme.colors.loopEmerald};
    cursor: pointer;
    font-family:'Work Sans';
}
`;

export const StyledSuccessImgWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: flex-end;
`;

export const StyledSuccessImg = styled.img`
    display: flex;
    @media (max-width: 980px) {
        width: 150px;
    }
`;
