import React from 'react';
import {
    StyledContent,
    StyledLeftSection,
    StyledPolicySection,
    StyledSIContainer,
    StyledPolicySectionContent,
    StyledSumInsuredSpan,
    StyledSumInsuredLabel,
    StyledCoveredMembersLabel,
    StyledCoveredMembersSpan
} from './styles';
import { ITopUpPolicyCard } from './types';
import { getSumInsured } from '../../../utils/common';
const TopUpPolicyCard: React.FunctionComponent<ITopUpPolicyCard> = ({ deductible, sumInsured }) => {
    return (
        <StyledContent>
            <StyledLeftSection>
                <StyledPolicySection>
                    <StyledPolicySectionContent>
                        <StyledCoveredMembersSpan>Super Top Up Sum Insured</StyledCoveredMembersSpan>
                        <StyledCoveredMembersLabel>₹{getSumInsured(sumInsured)}</StyledCoveredMembersLabel>
                    </StyledPolicySectionContent>
                </StyledPolicySection>
                <StyledSIContainer>
                    <StyledSumInsuredSpan>Base Policy Sum Insured</StyledSumInsuredSpan>
                    <StyledSumInsuredLabel>₹{getSumInsured(deductible)}</StyledSumInsuredLabel>
                </StyledSIContainer>
            </StyledLeftSection>
        </StyledContent>
    );
};

export default TopUpPolicyCard;
