import styled from 'styled-components';

export const StyledContainer = styled.div`
    height: 100vh;
    padding: 30px 50px;
    width: 33%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: ${(p) => p.theme.colors.loopEmerald};
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
    @media (max-width: 768px) {
        display: none;
    }
`;
export const StyledLogo = styled.img`
    max-width: 167px;
    height: auto;
    margin-bottom: 65px;
`;

export const StyledLeftContainerSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const StyledLeftContainerIcon = styled.img`
    width: 360px;
`;

export const StyledText = styled.p(
    (p) => `
    font-size: 1.5em;
    margin: 0 0 0 14px;
    color: ${p.theme.colors.white};
    font-weight: 400;
    @media (max-width: 1440px) {
        font-size: ${p.theme.fontSizes.heading};
    }
`
);

export const StyledSubtext = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    font-weight: 400;
    width:100%;
    display:block;
`
);

export const StyledSection = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
`;

export const StyledImageContainer = styled.div`
    width: 100%;
    margin-bottom: 50px;
`;
