export const TopUpConstants = {
    FIVE_LAKH_SI: '5 lakh',
    TEN_LAKH_SI: '10 lakhs',
    FIFTEEN_LAKH_SI: '15 lakhs',
    PARENTS_ONLY: 'Parents Only',
    SELF_SPOUSE: 'Self + Spouse',
    SELF_SPOUSE_KIDS: 'Self + Spouse + 2 Kids',
    SELF_SPOUSE_KIDS_PARENTS: 'Self + Spouse + 2 Kids + 2 Parents'
};
export const StarRatingsConstants = ['', 'Very Bad', 'Bad', 'Good', 'Very Good'];
