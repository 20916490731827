import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    width: 100%;
    /* min-width: 450px; */
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    color: ${(p) => p.theme.colors.loopEmerald};
    margin: 5px 0px;
    margin-top: -5px;
    font-size: ${(p) => p.theme.fontSizes.heading};
    font-weight: 100;
    width: 90%;
    margin-right: auto;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const StyledFeedbackWrapper = styled.div`
    display: flex;
    min-height: 150px;
    width: 100%;
`;

export const UserRegisteredButton = styled.button(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size: ${p.theme.fontSizes.body};
    background-color: ${p.theme.colors.coverUpGreen};
    font-weight: 700;
    border-radius: 16px;
    min-width: 150px;
    width:100%;
    cursor: pointer;
    padding: 18px 0px;
    display: flex;
    justify-content: center;
    border:none;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    @media (max-width: 768px) {
        margin-top: 15px;
        width: 70%;
    }
`
);

export const StyledImage = styled.img`
    width: 40px;
    margin-right: 10px;
`;

export const StyledContentImage = styled.img`
    width: 230px;
    margin: 10px 0px 20px 0px;
`;

export const StyledContentText = styled.p`
    color: ${(p) => p.theme.colors.beyondGrey};
    font-size: ${(p) => p.theme.fontSizes.body};
    letter-spacing: 0.02em;
    margin-bottom: 20px;
    margin-top: 0px;
`;

export const StyledContentDisclaimer = styled.p`
    color: ${(p) => p.theme.colors.beyondGrey};
    font-size: ${(p) => p.theme.fontSizes.label};
    letter-spacing: 0.02em;
    margin: 0px;
    width: 100%;
    margin-top: 15px;
`;

export const StyledInputWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    width: 98%;
`;
