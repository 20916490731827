import styled from 'styled-components';

export const StyledContainer = styled.div<{
    $bgColor: boolean;
}>`
    width: 100%;
    max-width: 100%;
    /* max-width: 900px; */
    /* border-bottom: 1px solid ${(p) => p.theme.colors.border}; */
    background-color: ${(p) => (p.$bgColor === true ? p.theme.colors.loopEmerald : p.theme.colors.white)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px 120px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 460px) {
        padding: 10px 20px;
    }
`;

export const Signout = styled.div<{
    $bgColor: boolean;
}>`
    font-weight: 700;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px 13px;
    margin: 0px 15px;
    color: ${(p) => (p.$bgColor === true ? p.theme.colors.white : p.theme.colors.loopEmerald)};
    font-size: ${(p) => p.theme.fontSizes.body};
    border: 1px solid ${(p) => (p.$bgColor === true ? p.theme.colors.white : p.theme.colors.loopEmerald)};
    &:hover {
        text-decoration: underline;
    }
`;

export const StyledLogo = styled.img`

    cursor: pointer;
    @media (max-width: 460px) {
        margin-top: 15px;
        padding: 20px 10px;
    }
`;

export const StyledMenuIcon = styled.img`
    margin-right: 10px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-end;
        margin-top: 0;

        /* margin-top: 25px; */
    }
`;

export const SaveButton = styled.div(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size: ${p.theme.fontSizes.mediumFont};
    background-color: ${p.theme.colors.coverUpGreen};
    font-weight: 700;
    cursor: pointer;
    border-radius: 10px;
    padding: 8px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:'Work Sans';
    
`
);
