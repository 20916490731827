import styled from 'styled-components';

export const StyledModalOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
`;

export const StyledModalContainer = styled.div<{ $borderRadius?: string }>`
    position: relative;
    background-color: ${(p) => p.theme.colors.white};
    margin: 10px;
    border-radius: ${(p) => p.$borderRadius || '30px'};
    min-height: 200px;
    min-width: 300px;
    /* max-width: 530px; */
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow-y: auto;
    @media (max-width: 768px) {
        width: 95%;
    }
`;
export const StyledAbsoluteCloseIcon = styled.img`
    position: absolute;
    top: 30px;
    right: 30px;
    margin: 0px;
    cursor: pointer;
    background-color: #595959;
    border-radius: 50%;
    padding: 6px;
`;

export const StyledCloseIcon = styled.img`
    cursor: pointer;
`;

export const StyledTitleContainer = styled.div<{
    $borderBottom?: string;
    $padding?: string;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${(p) => p.$padding || '30px'};
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-bottom: ${(p) => p.$borderBottom || `1px solid ${p.theme.colors.border}`};
`;

export const StyledTitle = styled.div<{ $color?: string }>`
    font-size: ${(p) => p.theme.fontSizes.title};
    color: ${(p) => p.$color || p.theme.colors.black};
    font-weight: bold;
`;
export const StyledModalContent = styled.div`
    padding: 30px;
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    overflow: auto;
    @media (max-width: 768px) {
        padding: 10px;
    }
`;
