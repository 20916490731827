import styled from 'styled-components';

export const StyledDiv = styled.div<{ $propStyles: string; $isInverted: boolean }>(
    (p) => `
    width: 100%;
    height: 100%;
    padding-bottom: 75px;
    ${p.$isInverted ? 'padding-top: 100px;' : ''}
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    ${p.$propStyles}
`
);
