import React, { useState } from 'react';
import theme from '../../../theme';
import { TextView } from '../TextView';
import { IToolTipView } from './types';
import { TooltipMainContainer, TooltipCantainer, InfoTagImage } from './styles';
const Tooltip: React.FunctionComponent<IToolTipView> = ({ Icon, Text }) => {
    const [showTopup, setShowTopup] = useState(false);

    return (
        <TooltipMainContainer>
            <InfoTagImage
                onMouseOut={() => setShowTopup(false)}
                onMouseOver={() => setShowTopup(true)}
                src={Icon}
            ></InfoTagImage>
            {showTopup && (
                <TooltipCantainer>
                    <TextView fontWeight='400' fontSize='14px' color={theme.colors.white}>
                        {Text}
                    </TextView>
                </TooltipCantainer>
            )}
        </TooltipMainContainer>
    );
};
export default Tooltip;
