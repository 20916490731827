import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const StyledContainer = styled.div<{ $styles: Record<string, string>; $endAnimationStart: boolean }>`
    padding: 12px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: ${(p) => p.$styles.backgroundColor};
    box-shadow: ${(p) => p.$styles.boxShadow};
    color: ${(p) => p.$styles.color};
    min-width: 320px;
    max-width: 370px;
    border-radius: 6px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    ${(p) =>
        (p.$endAnimationStart &&
            `
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.5s, opacity 0.5s linear;
    `) ||
        ''}
`;
export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
    flex-grow: 1;
    margin-right: 25px;
    overflow: hidden;
`;
export const StyledTitle = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    font-weight: bold;
`
);
export const StyledBody = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    max-height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 34px;
    width: 100%;
`
);
export const StyledIcon = styled.img`
    margin-right: 12px;
`;
export const StyledClose = styled(SVG)`
    position: absolute;
    margin-right: 10px;
    margin-top: 10px;
    top: 0;
    right: 0;
    & path {
        pointer-events: all;
        fill: ${(p) => p.theme.colors.white};
    }
`;
export const toastStyles = {
    error: {
        backgroundColor: '#EB5757',
        boxShadow: '0px 2px 26px rgba(215, 0, 0, 0.1)',
        color: 'white'
    },
    warning: {
        backgroundColor: '#FBDF00',
        boxShadow: '0px 2px 26px rgba(251, 223, 0, 0.1)',
        color: 'black'
    },
    success: {
        backgroundColor: '#6FCF97',
        boxShadow: '0px 2px 26px rgba(82, 215, 0, 0.1)',
        color: 'white'
    }
};
