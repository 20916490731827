import styled from 'styled-components';

export const TooltipCantainer = styled.div<{
}>`
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -11px;
        border-width: 10px;
        bottom: 100%;
        border-style: solid;
        border-color: transparent transparent ${(p) => p.theme.colors.beyondGrey} transparent;
    }
    background-color: ${(p) => p.theme.colors.beyondGrey};
    color: ${(p) => p.theme.colors.white};
    text-align: center;
    margin-left: -60px;
    width: 230px;
    border-radius: 12px;
    position: absolute;
    z-index: 1;
    top: 150%;
    right: -473%;
    padding: 16px;
`;
export const InfoTagImage = styled.img`
    margin-left: 8px;
    position: relative;
    top: 0;
    cursor: pointer;
    bottom: 0;
`;
export const TooltipMainContainer = styled.div`
    position: relative;
`;
