import React from 'react';
import { ImageContainer, ImageViewContainer } from './styles';
import { IImageView } from './types';

export const ImageView: React.FunctionComponent<IImageView> = ({
    src,
    width,
    height,
    containerMinHeight,
    containerMinWidth,
    backgroundColor,
    radius,
    marginLeft,
    marginRight,
    containerWidth,
    margin,
    rotate
}) => {
    return (
        <ImageViewContainer
            $width={containerWidth}
            $containerMinHeight={containerMinHeight}
            $containerMinWidth={containerMinWidth}
            $radius={radius}
            $backgroundColor={backgroundColor}
            $marginLeft={marginLeft}
            $marginRight={marginRight}
            $margin={margin}
        >
            <ImageContainer
                src={src}
                width={width}
                height={height}
                $rotate={rotate}
            />
        </ImageViewContainer>
    );
};
