import styled from 'styled-components';

export const StyledTopMargin = styled.div`
    width: 100%;
    margin-top: 20px;
`;

export const StyledErrorText = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.body};
`
);

export const StyledHeading = styled.span<{
    $textAlign?: string | 'center';
    $margin?: string;
}>(
    (p) => `
    // color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.body};
    display:flex;
    letter-spacing: -0.02em;
    font-weight: 500;
    color: ${p.theme.colors.loopEmerald};
    margin: ${p?.$margin || '0px 0px 12px 0px'};
    width: 100%;
    text-align: ${p.$textAlign};
    display: block;
`
);

export const StyledPhoneNumContainer = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.body};
    display:flex;
`
);

export const StyledStaticInput = styled.input(
    (p) => `
    max-width: 100%;
    width: 18%;
    background-color: #edeff3;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    font-size: ${p.theme.fontSizes.body};
    border-radius: 8px;
    box-shadow: 0px 4px 10px ${p.theme.colors.activeBG};
    padding: 20px 20px;
    margin-right:10px;
    border:none;
    color:#B9B9B9;
    @media (max-width: 768px) {
        width: 22%;
        
    }
`
);
