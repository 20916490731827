import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const IconContainer = styled.div`
    background-color: ${(p) => p.theme.colors.activeBG};
    border-radius: 8px;
    height: 34px;
    width: 34px;
    margin-right: 15px;
`;
export const StyledText = styled.span`
    font-size: ${(p) => p.theme.fontSizes.heading};
    color: ${(p) => p.theme.colors.muted};
`;
export const StyledSVG = styled(SVG)<{ $isActive: boolean }>`
    & path {
        pointer-events: all;
        fill: ${(p) =>
        p.$isActive ? p.theme.colors.primary : p.theme.colors.muted};
    }
`;
export const StyledContainer = styled.div<{ isActive: boolean }>`
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    &:hover {
        cursor: pointer;
    }
    &:hover ${IconContainer} {
        background-color: ${(p) => p.theme.colors.primaryBG};
    }
    &:hover ${IconContainer} ${StyledSVG} {
        & path {
            pointer-events: all;
            fill: ${(p) => p.theme.colors.primary};
        }
    }
    &:hover ${StyledText} {
        color: ${(p) => p.theme.colors.primary};
    }
    ${(p) =>
        p.isActive &&
        `
        ${IconContainer} {
            background: ${p.theme.colors.primaryBG};
        }
        ${StyledText} {
            color: ${p.theme.colors.primary};
        }
    `}
`;
