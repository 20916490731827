import React from 'react';
import FeedbackStar from '../../atoms/FeedbackStar';
import { IFeedbackStarRatings } from './types';
import { StyleWrapper, StyleStarContainer, StyleStarValueContainer } from './styles';
import { StarRatingsConstants } from '../../../utils/constants/TopUpConstants';
const FeedbackStarRatings: React.FunctionComponent<IFeedbackStarRatings> = ({ onChange }) => {
    const [rating, setRating] = React.useState(0);
    const changeRating = (newRating: any) => {
        setRating(newRating);
        onChange?.(newRating);
    };
    return (
        <StyleWrapper>
            <StyleStarContainer>
                {[1, 2, 3, 4].map((value) => (
                    <FeedbackStar key={value} filled={value <= rating} onClick={() => changeRating(value)} />
                ))}
            </StyleStarContainer>
            <StyleStarValueContainer>{StarRatingsConstants[rating]}</StyleStarValueContainer>
        </StyleWrapper>
    );
};
export default FeedbackStarRatings;
