import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
    background-color: ${(p) => p.theme.colors.loopEmerald};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 60px 120px;
    max-height: 400px;
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 30px 20px;
        max-height: initial;
    }
`;

export const StyledContainerLeft = styled.div`
    display: flex;
    width: 40%;
    flex-direction: column;
    /* padding: 50px; */
    background-size: contain;
    align-items: flex-start;
    padding: 0px 80px;
    @media (max-width: 768px) {
        width:100%;
        padding: 0px;
    }
    
}
`;

export const StyledContainerRight = styled.div`
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0px;
    }
`;

export const StyledAddressText = styled.p`
    line-height: 28px;
    font-style: normal;
`;

export const StyledSocialWrapper = styled.div`
    display: grid;
    margin-top: 4em;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    justify-content: start;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 1.8em;
    grid-row-gap: 0rem;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
`;
export const StyledLogo = styled.img`
    width: 130px;
    height: auto;
    cursor: pointer;
`;

export const StyledSocialIcons = styled.a`
    cursor: pointer;
`;

export const StyledPoweredWrapper = styled.div`
    display: flex;
`;
