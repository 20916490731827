import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';
import FirebaseProvider from '../../../../adapters/provider';

export const Firestore = FirebaseProvider.firestore();
export const FirebaseStorage = FirebaseProvider.storage();
export const FirebaseFunctions = FirebaseProvider.functions('asia-south1');
export const FirebaseCaptcha = firebase.auth;
export const FirebaseAuth = firebase.auth();
export const FirebaseTimestamp = firebase.firestore.Timestamp;
export const FirebaseFieldValue = firebase.firestore.FieldValue;
export default FirebaseProvider;
