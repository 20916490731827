import styled, { keyframes } from 'styled-components';

export const SlideInAnimate = keyframes`
    100% { right: 0; }
`;
export const StyledContainer = styled.div`
    position: absolute;
    right: -450px;
    padding: 0px 30px;
    height: 100%;
    width: 450px;
    overflow: hidden;
    min-width: 250px;
    background-color: ${(p) => p.theme.colors.white};
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    -webkit-animation: ${SlideInAnimate} 0.5s forwards;
    animation: ${SlideInAnimate} 0.5s forwards;
    z-index: 2;
`;
export const StyledImage = styled.img`
    max-width: 140px;
    height: auto;
    cursor: pointer;
    margin: 25px;
`;

export const NavigationItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 35px 0px;
    width: 100%;
`;
