import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    width: 100%;
    /* min-width: 450px; */
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    color: ${(p) => p.theme.colors.loopEmerald};
    margin: 5px 0px;
    margin-top: -5px;
    font-size: ${(p) => p.theme.fontSizes.title};
    font-weight: 100;
    width: 90%;
    margin-right: auto;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const StyledFeedbackWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: ${(p) => p.theme.colors.loopEmerald};
    min-width: 450px;
    border-radius: 16px;
    margin: 35px 0px;
`;

export const StyledImage = styled.img`
    width: 40px;
    margin-right: 10px;
`;

export const StyledContentImage = styled.img`
    width: 170px;
    margin-top: 15px;
`;

export const StyledContentText = styled.p`
    color: ${(p) => p.theme.colors.loopEmerald};
    font-size: ${(p) => p.theme.fontSizes.heading};
    letter-spacing: 0.02em;
    margin-bottom: 20px;
    margin-top: 0px;
    letter-spacing: -0.02em;
    padding: 0 20px;
    text-align: center;
    line-height: 28px;
`;

export const StyledContentDisclaimer = styled.p`
    color: ${(p) => p.theme.colors.beyondGrey};
    font-size: ${(p) => p.theme.fontSizes.label};
    letter-spacing: 0.02em;
    margin: 0px;
    width: 100%;
    margin-top: 15px;
`;

export const StyledSuccessTextBTN = styled.button`
    padding: 15px;
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: ${(p) => p.theme.colors.loopEmerald};
    background-color:${(p) => p.theme.colors.coverUpGreen};
    cursor: pointer;
    width: 100%;
    justify-content: center;
    font-family:'Work Sans';
    font-size:14px;
}
`;
export const StyledBTNWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    width: 100%;
`;
export const StyledSuccessImgWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: flex-end;
`;
