import React from 'react';
import { ContentText } from './styles';
import { ITextView } from './types';

export const TextView: React.FunctionComponent<ITextView> = ({
    color,
    fontSize,
    margin,
    textAlign,
    fontWeight,
    fontTypography,
    lineHeight,
    ...props
}) => {
    return (
        <ContentText
            $typography={fontTypography}
            color={color}
            $fontSize={fontSize}
            $margin={margin}
            $fontWeight={fontWeight}
            $textAlign={textAlign}
            $lineHeight={lineHeight}
        >
            {props.children}
        </ContentText>
    );
};
