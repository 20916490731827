interface ISegmentAct {
    name: string;
    properties?: Record<string, unknown>;
}
interface ISegmentActions {
    PAGE: Record<string, ISegmentAct>;
    CLICK: Record<string, ISegmentAct>;
    IDENTIFY: Record<string, ISegmentAct>;
}
export const SEGMENT_ACTIONS: ISegmentActions = {
    PAGE: {
        LANDING_PAGE: {
            name: 'TopUp_Landing_Page_View',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        LOGIN_PAGE: {
            name: 'LOGIN PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        OTP_PAGE: {
            name: 'OTP PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        TWOFA_PAGE: {
            name: 'TWO FA PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        CONFIRMATION_PAGE: {
            name: 'CONFIRMATION PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        DASHBOARD_PAGE: {
            name: 'DASHBOARD PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        RECOMMENDATION_PAGE: {
            name: 'RECOMMENDATION PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        POLICY_EXPIRED_PAGE: {
            name: 'GMC POLICY EXPIRED PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        ENROLLMENT_DATE_EXPIRED_PAGE: {
            name: 'ENROLLMENT DATE EXPIRED PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        SUMMARY_PAGE: {
            name: 'SUMMARY PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        WELCOME_PAGE: {
            name: 'WELCOME PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        },
        UNKNOWN_PAGE: {
            name: 'UNKNOWN PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path
            }
        }
    },
    CLICK: {
        LOGIN_VERIFY_OTP_BUTTON: {
            name: 'LOGIN_VERIFY_OTP_BUTTON',
            properties: {
                describe: 'User has chosen to login with mobile'
            }
        },
        LOGIN_OAUTH: {
            name: 'LOGIN_OAUTH',
            properties: {
                describe: `User has chosen to login with OAuth`
            }
        },
        VERIFY_OTP: {
            name: 'VERIFY_OTP',
            properties: {
                describe: 'User has chosen to verify his OTP'
            }
        },
        RESEND_OTP: {
            name: 'RESEND_OTP',
            properties: {
                describe: 'User has chosen to resend OTP'
            }
        },
        EDIT_PHONE_NUMBER: {
            name: 'EDIT_PHONE_NUMBER',
            properties: {
                describe: 'User has chosen to go back and edit his phone number'
            }
        },
        WELCOME_PAGE_PROCEED: {
            name: 'WELCOME_PAGE_PROCEED',
            properties: {
                describe: 'User has clicked on Proceed button on the Welcome Page'
            }
        },
        NAVIGATION_HEADER_LOGO: {
            name: 'NAVGATION_HEADER_LOGO',
            properties: {
                describe: 'User has clicked on the logo present in Navigation Header to redirect to dashboard.'
            }
        },
        NAVIGATION_HEADER_SIGNOUT: {
            name: 'NAVIGATION_HEADER_SIGNOUT',
            properties: {
                describe: 'User has clicked on the signout button in Navigation Header.'
            }
        },
        TOPUP_LEARN_MORE: {
            name: 'TOPUP_LEARN_MORE',
            properties: {
                describe: `User has clicked on 'Learn more'
                in the first section on Landing Page`
            }
        },
        TOPUP_SECTION_WHAT: {
            name: 'TOPUP_SECTION_WHAT',
            properties: {
                describe: `User has clicked on 'View Options'
                in the what are the advantages section on Landing Page`
            }
        },
        TOPUP_SECTION_HOW: {
            name: 'TOPUP_SECTION_HOW',
            properties: {
                describe: `User has clicked on 'Explore options now'
                in the HOW DOES THIS WORK section on Landing Page`
            }
        },
        TOPUP_PLAN_SELECTION: {
            name: 'TOPUP_PLAN_SELECTION',
            properties: {
                describe: `User has clicked on the Select Option button on Landing Page`,
                Plan: (plan: string) => plan,
                Amount: (amount: string) => amount
            }
        },
        TERMS_LANDING: {
            name: 'TERMS_LANDING',
            properties: {
                describe: 'User has clicked on a terms & Condition on landing Page'
            }
        },
        TERMS_SUMMARY: {
            name: 'TERMS_SUMMARY',
            properties: {
                describe: 'User has clicked on a terms & Condition on Summary'
            }
        },
        FEEDBACK_SUBMITTED: {
            name: 'FEEDBACK_SUBMITTED',
            properties: {
                describe: 'User has clicked on a feedback submit btn on Star Ratting Modal'
            }
        },
        MEDICAL_CONDITIONS: {
            name: 'MEDICAL_CONDITIONS',
            properties: {
                describe: 'User has clicked on a Medical Conditions'
            }
        },
        TALK_TO_US: {
            name: 'TALK_TO_US',
            properties: {
                describe: 'User has clicked on a talk to us button'
            }
        },
        TALK_TO_EXPERT: {
            name: 'TALK_TO_EXPERT',
            properties: {
                describe: 'User has clicked on a talk to expert button on Landing page'
            }
        },
        TALK_TO_US_LANDING: {
            name: 'TALK_TO_US_LANDING',
            properties: {
                action: 'TALK_TO_US_LANDING',
                describe: 'User has clicked on a talk to us button on Landing Page'
            }
        },
        TALK_TO_US_RECOMMENDATION: {
            name: 'TALK_TO_US_RECOMMENDATION',
            properties: {
                action: 'TALK_TO_US_RECOMMENDATION',
                describe: 'User has clicked on a talk to us button on Recommendation Page'
            }
        },
        TALK_TO_US_SUMMARY: {
            name: 'TALK_TO_US_SUMMARY',
            properties: {
                action: 'TALK_TO_US_SUMMARY',
                describe: 'User has clicked on a talk to us button on Summary Page'
            }
        },
        CONFIRMATION_REDIRECT_TO_STORE: {
            name: 'CONFIRMATION_REDIRECT_TO_STORE',
            properties: {
                describe: (store: string) =>
                    `User has choosen/clicked to redirect to the ${store} Store to download the loophealt app`,
                store: (store: string) => `${store} Store`
            }
        },
        PROCEED_TO_BUY_SUMMARY: {
            name: 'PROCEED_TO_BUY_SUMMARY',
            properties: {
                describe: 'User has clicked on a Proceed to buy button on Summary Page',
                user_id: (userId: string) => `${userId}`,
                sum_insured: (sumInsured: string) => `${sumInsured}`,
                selected_members: (selectedMembers: string) => `${selectedMembers}`,
                deductible: (deductible: string) => `${deductible}`,
                annual_premium: (annualPremium: string) => `${annualPremium}`
            }
        },
        PROCEED_TO_BUY_RECOMMENDATION: {
            name: 'PROCEED_TO_BUY_RECOMMENDATION',
            properties: {
                describe: 'User has clicked on a Proceed to buy button on RECOMMENDATION Page',
                user_id: (userId: string) => `${userId}`,
                sum_insured: (sumInsured: string) => `${sumInsured}`,
                selected_members: (selectedMembers: string) => `${selectedMembers}`,
                deductible: (deductible: string) => `${deductible}`,
                annual_premium: (annualPremium: string) => `${annualPremium}`
            }
        }
    },
    IDENTIFY: {
        USER_LOGIN: { name: 'USER_LOGIN' },
        USER_LOGOUT: { name: 'USER_LOGOUT' }
    }
};
