import { FirebaseAuth } from '../../adapters/provider';
import { IToken } from './types';

const useToken = (tokenName = 'token'): IToken => {
    const setToken = (token: string): void => {
        document.cookie = `${tokenName}=${token}`;
    };

    const getToken = () => {
        const tokens = document.cookie;
        const name = tokenName + '=';
        const ca = tokens.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };

    const checkToken = (apiResponse = ''): string => {
        const token = getToken();
        if (token.length) {
            if (apiResponse.length && apiResponse === 'Failed to verify user') return '';

            return token;
        }
        return '';
    };

    const generateFirebaseToken = async () => {
        const tok = (await FirebaseAuth.currentUser?.getIdToken(true)) || '';
        setToken(tok);
    };
    return {
        generateFirebaseToken,
        checkToken,
        setToken
    };
};

export default useToken;
