import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { IToastContext } from '..';
import { createLoopId } from '../../../common';
import Toast from '../Toast';
import { IToast, IToastType } from '../Toast/types';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 20px;
`;
const ToastWrapper = styled.div`
    margin: 10px 0px;
    z-index: 1000000000;
`;

export const ToastContext = React.createContext<IToastContext | null>(null);
const ToastProvider: React.FunctionComponent = ({ children }) => {
    const currentList = React.useRef<IToast[]>([]);
    const [list, setList] = React.useState<IToast[]>([]);

    const toastRef = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (!toastRef.current) {
            toastRef.current = document.createElement('div');
        }
        if (toastRef.current) {
            const rootElem = document.createElement('div');
            rootElem.setAttribute('id', 'toast-root-loop');
            if (document.body.lastElementChild) {
                document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
            }
            rootElem.appendChild(toastRef.current);
        }
    }, [toastRef.current]);
    React.useEffect(() => {
        if (!toastRef.current) {
            toastRef.current = document.createElement('div');
        }
    }, []);
    const addToastMessage = (type: IToastType, title: string, body: string, expires = 5000): string => {
        const id = createLoopId('LPT');
        const newList = [
            ...currentList.current,
            {
                id,
                type,
                body,
                title,
                expires
            }
        ];
        setList(newList);
        currentList.current = newList;
        setTimeout(() => {
            removeToastMessage(id);
        }, expires);
        return id;
    };
    const removeToastMessage = (id: string): string => {
        const toast = currentList.current.filter((toast) => toast.id === id)[0];
        const newList = currentList.current.filter((toast) => toast.id !== id);
        const forAnimationList = [
            ...newList,
            {
                ...toast,
                forceExitAnimation: true
            }
        ];
        setList(forAnimationList);
        currentList.current = forAnimationList;
        setTimeout(() => {
            setList(newList);
            currentList.current = newList;
        }, toast.expires);
        return id;
    };
    return (
        <ToastContext.Provider value={{ displayToast: addToastMessage, removeToast: removeToastMessage }}>
            {children}
            {toastRef.current &&
                createPortal(
                    <StyledContainer>
                        {list.map((toast) => (
                            <ToastWrapper key={toast.id}>
                                <Toast
                                    key={toast.id}
                                    id={toast.id}
                                    expires={toast.expires}
                                    type={toast.type as IToastType}
                                    title={toast.title}
                                    body={toast.body}
                                    forceExitAnimation={toast.forceExitAnimation}
                                />
                            </ToastWrapper>
                        ))}
                    </StyledContainer>,
                    toastRef.current
                )}
        </ToastContext.Provider>
    );
};

export default ToastProvider;
