import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 90%;
`;

// export const StyledContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
// `;

export const StyledDescription = styled.p(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size:  ${p.theme.fontSizes.title};
    font-style: normal;
    font-weight: 100;
    line-height: 38px;
    letter-spacing: -0.02em;
`
);

export const StyledContainerWrapper = styled.div`
    width: 90%;
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    @media (max-width: 768px) {
        padding: 0% 5%;
        margin-top: 0px;
        flex-direction: column;
    }
`;
export const StyledLeftContainer = styled.div`
    width: 55%;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const StyledRightContainer = styled.div`
    width: 45%;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const StyledTitle = styled.h4(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size:  ${p.theme.fontSizes.largeFont};
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: -0.02em;
    margin-bottom:0;
`
);

export const StyledTitleSpan = styled.span(
    (p) => `
    color: ${p.theme.colors.purple};
    font-size:  ${p.theme.fontSizes.largeFont};
`
);
