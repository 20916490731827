import { ITopUpLandingSlice } from './types';

export const initialState: ITopUpLandingSlice = {
    crudTopUp: {
        data: null,
        error: null,
        loading: false
    },
    policyTopUpData: {
        data: null,
        error: null,
        loading: false
    },
    TopUpDataFaq: {
        data: null,
        error: null,
        loading: false
    },
    policySelectionData: null
};
