import React, { useState } from 'react';
import { IFormInput } from './types';

const useFormInput = (initalValue: string): IFormInput => {
    const [value, setValue] = useState<string>(initalValue);
    const [debounceValue, setDebounceValue] = useState<string>(value);
    const [typingTimeout, setTypingTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [errorText, setErrorText] = useState<string>('');

    const finalValidationCheck = (): boolean => {
        let validity = true;
        if (!value.length) {
            setErrorText('This field cannot be empty');
            validity = false;
        }
        if (errorText) {
            validity = false;
        }
        return validity;
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formValue = e.currentTarget.value;
        const formName = e.currentTarget.name;
        if (typingTimeout) {
            clearTimeout(typingTimeout);
            setErrorText('');
        }
        setTypingTimeout(
            setTimeout(() => {
                if (formValue.length > 9) {
                    setErrorText(formValidator(formName, formValue));
                }
                setDebounceValue(formValue);
            }, 350)
        );
        setValue(formValue);
    };
    return {
        value,
        debounceValue,
        errorText,
        setErrorText,
        setValue,
        onChange,
        finalValidationCheck
    };
};

const formValidator = (name: string, value: string): string => {
    let errorValue = '';
    switch (name) {
    case 'text': {
        if (!value.trim().length) {
            errorValue = 'Please enter a valid value';
        }
        break;
    }
    case 'address': {
        if (!value.trim().length) {
            errorValue = 'Please enter a valid address';
        }
        break;
    }
    case 'email': {
        if (!regexValidations.email.test(value.toLowerCase())) {
            errorValue = 'Please enter a valid email';
        }
        break;
    }
    case 'phone': {
        if (!value.trim().length) {
            errorValue = 'Phone number cannot be empty';
        } else if (!regexValidations.phone.test(value.toLowerCase())) {
            errorValue = 'Please enter a valid phone number';
        }
        break;
    }
    case 'password': {
        if (!value.length) {
            errorValue = 'Please enter a valid password';
        } else if (value.length < 6 || value.length > 32) {
            errorValue = 'Password should be at least from 6 to 32 characters';
        } else if (!regexValidations.password.test(value)) {
            errorValue = `Password should have to include 
                alpha-numberic and special characters`;
        }
        break;
    }
    default:
        break;
    }

    return errorValue;
};

const regexValidations = {
    email: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+[.][a-zA-Z]+$/,
    phone: /^[6789]\d{9}$/,
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-]).{6,32}$/
};

export default useFormInput;
