import styled from 'styled-components';

export const StyledContent = styled.div`
    display: flex;
`;

export const StyledLeftSection = styled.div`
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledPolicySection = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledPolicySectionImg = styled.div<{
    $src: string;
}>`
    width: 62px;
    height: 62px;
    border-radius: 12px;
    background: ${(p) => p.theme.colors.white};
    background-image: url(${(p) => p.$src});
    background-repeat: no-repeat;
    background-size: contain;
}
`;

export const StyledPolicySectionContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledPolicyLabel = styled.label(
    (p) => `
    color: ${p.theme.colors.white};
    font-size:  ${p.theme.fontSizes.heading};
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    margin-bottom:6px;
`
);

export const StyledPolicySpan = styled.span(
    (p) => `
    color: ${p.theme.colors.white};
    font-size:  ${p.theme.fontSizes.body};
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
`
);

export const StyledSIContainer = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    padding-left: 40px;
    border-left: 1px solid #498a7d;
    /* border-right: 1px solid #498a7d; */
    @media (max-width: 768px) {
        border: none;
        width: 100%;
        padding: 0;
        margin-top: 20px;
    }
`;

export const StyledSumInsuredLabel = styled.label(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size:  ${p.theme.fontSizes.heading};
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
`
);
export const StyledSumInsuredSpan = styled.span(
    (p) => `
    color: ${p.theme.colors.midGrey};
    font-size:  ${p.theme.fontSizes.body};
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom:5px;
`
);

export const StyledCoveredMembersLabel = styled.label(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size:  ${p.theme.fontSizes.heading};
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    margin-bottom:5px;
`
);
export const StyledCoveredMembersSpan = styled.span(
    (p) => `
    color: ${p.theme.colors.midGrey};
    font-size:  ${p.theme.fontSizes.body};
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom:5px;
`
);

export const StyledCMContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    justify-content: center;
`;
