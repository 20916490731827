import { FirebaseAuth } from '../../adapters/provider';
import { IToken } from './types';
import { TOKENS } from '../constants/Tokens';
const useToken = (): IToken => {
    const setToken = async (): Promise<any> => {
        const token = await FirebaseAuth.currentUser?.getIdToken(true);
        document.cookie = `${TOKENS.FIREBASE_AUTH_TOKEN}=${token}`;
        return token;
    };

    const getToken = async (token: string) => {
        const name = token + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };

    const checkToken = async (): Promise<string> => {
        const token = await getToken(TOKENS.FIREBASE_AUTH_TOKEN);
        if (token) {
            return token;
        } else {
            const settoken = await setToken();
            return settoken;
        }
    };

    const tokenIsExpired = (message: string): boolean => {
        if (message === 'Failed to verify user') {
            return true;
        }
        return false;
    };

    return {
        setToken,
        checkToken,
        tokenIsExpired
    };
};

export default useToken;
